
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin::v-deep {
    color: $black;
    background-color: $white;

    .type-form {
        height: 90vh;
        display: flex;
        justify-content: center;

        [data-tf-widget] {
            height: 90vh !important;
        }
    }
}
